import {getAssetsPrefix} from '../../utils/getAssetsPrefix/getAssetsPrefix';

const ASSETS = {
  components: {
    header: {
      About: getAssetsPrefix('components/header/About.webp'),
      BFLogo: getAssetsPrefix('components/header/BFLogo.webp'),
      Contact: getAssetsPrefix('components/header/Contact.webp'),
      PixwardLogo: getAssetsPrefix('components/header/PixwardLogo.webp'),
    },
    footer: {
      desktop: {
        C2023: getAssetsPrefix('components/footer/C2023.webp'),
        FooterBg1: getAssetsPrefix('components/footer/FooterBg1.webp'),
        FooterBg2: getAssetsPrefix('components/footer/FooterBg2.webp'),
      },
      mobile: {
        FollowUs: getAssetsPrefix('components/footer/mobile/FollowUs.webp'),
        FooterMobileBg: getAssetsPrefix('components/footer/mobile/FooterMobileBg.webp'),
      },
    },
    InfiniteCarousel: {
      InfiniteCarouselBg: getAssetsPrefix('components/InfiniteCarousel/InfiniteCarouselBg.webp'),
      partners: {
        OpenLoot: getAssetsPrefix('components/InfiniteCarousel/partners/OpenLoot.webp'),
        BigTime: getAssetsPrefix('components/InfiniteCarousel/partners/BigTime.webp'),
        Elixir: getAssetsPrefix('components/InfiniteCarousel/partners/Elixir.webp'),
        MasterVentures: getAssetsPrefix('components/InfiniteCarousel/partners/MasterVentures.webp'),
        Metavest: getAssetsPrefix('components/InfiniteCarousel/partners/Metavest.webp'),
        Seedify: getAssetsPrefix('components/InfiniteCarousel/partners/Seedify.webp'),
        AlphaCrypto: getAssetsPrefix('components/InfiniteCarousel/partners/AlphaCrypto.webp'),
        Pvp: getAssetsPrefix('components/InfiniteCarousel/partners/Pvp.webp'),
        Owned: getAssetsPrefix('components/InfiniteCarousel/partners/Owned.webp'),
      }
    }
  },
  mainPage: {
    main: {
      desktop: {
        Arrow: getAssetsPrefix('mainPage/main/Arrow.webp'),
        InnovationsBg: getAssetsPrefix('mainPage/main/InnovationsBg.webp'),
        Lightning: getAssetsPrefix('mainPage/main/Lightning.webp'),
        MainBlockBg1: getAssetsPrefix('mainPage/main/MainBlockBg1.webp'),
        MainBlockBg2: getAssetsPrefix('mainPage/main/MainBlockBg2.webp'),
        MainBlockBg3: getAssetsPrefix('mainPage/main/MainBlockBg3.webp'),
        Person1: getAssetsPrefix('mainPage/main/Person1.webp'),
        Person2: getAssetsPrefix('mainPage/main/Person2.webp'),
        Person3: getAssetsPrefix('mainPage/main/Person3.webp'),
        Person4: getAssetsPrefix('mainPage/main/Person4.webp'),
        Person5: getAssetsPrefix('mainPage/main/Person5.webp'),
        Person6: getAssetsPrefix('mainPage/main/Person6.webp'),
        PioneeringBg: getAssetsPrefix('mainPage/main/PioneeringBg.webp'),
        PixwardGames: getAssetsPrefix('mainPage/main/PixwardGames.webp'),
        PixwardGamesBg: getAssetsPrefix('mainPage/main/PixwardGamesBg.webp'),
        Smile: getAssetsPrefix('mainPage/main/Smile.webp'),
      },
      mobile: {
        Arrow: getAssetsPrefix('mainPage/main/mobile/Arrow.webp'),
        InnovationsBg: getAssetsPrefix('mainPage/main/mobile/InnovationsBg.webp'),
        PioneeringBg: getAssetsPrefix('mainPage/main/mobile/PioneeringBg.webp'),

        MainBlockBg1: getAssetsPrefix('mainPage/main/mobile/MainBlockBg1.webp'),
        MainBlockBg2: getAssetsPrefix('mainPage/main/mobile/MainBlockBg2.webp'),
        MainBlockBg3: getAssetsPrefix('mainPage/main/mobile/MainBlockBg3.webp'),
        MainBlockRowBg: getAssetsPrefix('mainPage/main/mobile/MainBlockRowBg.webp'),
        Person1: getAssetsPrefix('mainPage/main/mobile/Person1.webp'),
        Person2: getAssetsPrefix('mainPage/main/mobile/Person2.webp'),
        Person3: getAssetsPrefix('mainPage/main/mobile/Person3.webp'),

      },
    },
    leadership: {
      desktop: {
        Alice: getAssetsPrefix('mainPage/leadership/Alice.webp'),
        Andy: getAssetsPrefix('mainPage/leadership/Andy.webp'),
        Kamila: getAssetsPrefix('mainPage/leadership/Kamila.webp'),
        OleksandrPankov: getAssetsPrefix('mainPage/leadership/OleksandrPankov.webp'),
        Roman: getAssetsPrefix('mainPage/leadership/Roman.webp'),
        Vadym: getAssetsPrefix('mainPage/leadership/Vadym.webp'),
        InfiniteCarouselBg: getAssetsPrefix('mainPage/leadership/InfiniteCarouselBg.webp'),
        LeadersBg: getAssetsPrefix('mainPage/leadership/LeadersBg.webp'),
        Leadership: getAssetsPrefix('mainPage/leadership/Leadership.webp'),
        LeadershipBg: getAssetsPrefix('mainPage/leadership/LeadershipBg.webp'),
        LeadershipBlockBg1: getAssetsPrefix('mainPage/leadership/LeadershipBlockBg1.webp'),
        LeadershipBlockBg2: getAssetsPrefix('mainPage/leadership/LeadershipBlockBg2.webp'),
        Linkedin: getAssetsPrefix('mainPage/leadership/Linkedin.webp'),
        Paint: getAssetsPrefix('mainPage/leadership/Paint.webp'),
      },
      mobile: {
        Arrow: getAssetsPrefix('mainPage/leadership/mobile/Arrow.webp'),
        LeadershipMobileBg: getAssetsPrefix('mainPage/leadership/mobile/LeadershipMobileBg.webp'),
        LeadershipMobileBg1: getAssetsPrefix('mainPage/leadership/mobile/LeadershipMobileBg1.webp'),
        LeadershipMobileBg2: getAssetsPrefix('mainPage/leadership/mobile/LeadershipMobileBg2.webp'),
        LeadershipMobileBg3: getAssetsPrefix('mainPage/leadership/mobile/LeadershipMobileBg3.webp'),
        LeadershipMobileTopBg: getAssetsPrefix('mainPage/leadership/mobile/LeadershipMobileTopBg.webp'),
        Paint: getAssetsPrefix('mainPage/leadership/mobile/Paint.webp'),
      },
      partners: {}
    },
    about: {
      desktop: {
        AboutBlockBg: getAssetsPrefix('mainPage/about/AboutBlockBg.webp'),
        BossFighters: getAssetsPrefix('mainPage/about/BossFighters.webp'),
        Crown: getAssetsPrefix('mainPage/about/Crown.webp'),
        GoToWebsite: getAssetsPrefix('mainPage/about/GoToWebsite.webp'),
        MetaQuest: getAssetsPrefix('mainPage/about/MetaQuest.webp'),
        Steam: getAssetsPrefix('mainPage/about/Steam.webp'),
      },
      mobile: {
        AboutBlockMobileBg: getAssetsPrefix('mainPage/about/mobile/AboutBlockMobileBg.webp'),
        BossFightersMobile: getAssetsPrefix('mainPage/about/mobile/BossFightersMobile.webp'),
      },
    },
    contact: {
      desktop: {
        ContactBg1: getAssetsPrefix('mainPage/contact/ContactBg1.webp'),
        ContactBg2: getAssetsPrefix('mainPage/contact/ContactBg2.webp'),
        ContactBg3: getAssetsPrefix('mainPage/contact/ContactBg3.webp'),
        ContactInfo: getAssetsPrefix('mainPage/contact/ContactInfo.webp'),
        FormBg: getAssetsPrefix('mainPage/contact/FormBg.webp'),
        FormBtn: getAssetsPrefix('mainPage/contact/FormBtn.webp'),
      },
      mobile: {
        ContactInfoMobileBg: getAssetsPrefix('mainPage/contact/mobile/ContactInfoMobileBg.webp'),
        FormBg: getAssetsPrefix('mainPage/contact/mobile/FormBg.webp'),
      },
    },
  },
};

export default ASSETS