import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import AppContent from '../AppContent';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import LazyRecaptcha from '../LazyRecaptcha/LazyRecaptcha';

import {Wrapper} from './styles';

const DefaultLayout = () => {
  const navigate = useNavigate();

  const [isMainPage, setIsMainPage] = useState<boolean>(false)

  useEffect(() => {
    if (window.location.pathname === '/') {
      setIsMainPage(true)
    } else {
      setIsMainPage(false)
    }
  }, [navigate])

  return (
    <Wrapper>
      <Header/>
      <AppContent/>
      <Footer/>
      {isMainPage && <LazyRecaptcha/>}
    </Wrapper>
  );
};
export default DefaultLayout;
