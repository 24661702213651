import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import useHeaderAnimation from '../../hooks/useHeaderAnimation/useHeaderAnimation';
import useScreenSizeHook, {SCREEN_TYPES} from '../../hooks/useScreenSizeHook/useScreenSizeHook';

import ASSETS from '../../assets/images/constants';
import {setItem} from '../../localStorage';
import {LOCAL_STORAGE_NAMES} from '../../localStorage/types';

import {BurgerLine, BurgerMenu, BurgerWrapper, HeaderLi, HeaderLogo, HeaderUl, LogoWrapper, Wrapper,} from './styles';
import './index.css';

const Header = () => {
  const isHeaderVisible: boolean = useHeaderAnimation();
  const {screenType} = useScreenSizeHook();
  const navigate = useNavigate();

  const [isBurgerMenuVisible, setIsBurgerMenuVisible] = useState<boolean>(false);
  const [isOutAnimation, setIsOutAnimation] = useState<boolean>(true);

  const scrollToElement = (elemId: string) => {
    const targetElement = document.getElementById(elemId);

    if (screenType !== SCREEN_TYPES.DESKTOP) {
      menuClickHandler()
    }

    if (targetElement) {
      targetElement.scrollIntoView({behavior: 'smooth'})
    } else {
      setItem(LOCAL_STORAGE_NAMES.SCROLL_TO, elemId);
      navigate('/')
    }
  }

  const menuClickHandler = () => {
    if (isBurgerMenuVisible) {
      setIsOutAnimation(true)
      setTimeout(() => setIsBurgerMenuVisible(false), 300)
    } else {
      setIsOutAnimation(false)
      setIsBurgerMenuVisible(true)
    }
  }

  useEffect(() => {
    if (isBurgerMenuVisible) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [isBurgerMenuVisible])

  return (
    <Wrapper
      className={isBurgerMenuVisible ? 'header-visible' : (isHeaderVisible ? 'header-visible' : 'header-hidden')}
      height={screenType === SCREEN_TYPES.DESKTOP ? 9.5 : 11.5}
      padding={screenType === SCREEN_TYPES.DESKTOP ? 3.7 : 7}>
      <LogoWrapper>
        <HeaderLogo src={ASSETS.components.header.PixwardLogo}
          width={screenType === SCREEN_TYPES.DESKTOP ? 22 : 37.1}
          height={screenType === SCREEN_TYPES.DESKTOP ? 5 : 5} className='scaleOnHover'
          onClick={() => {
            if (window.location.href.includes('privacy-policy')) {
              navigate('/')
            } else {
              window.scrollTo({top: 0, behavior: 'smooth'})
            }
          }}
          alt='Pixward' title='Pixward' aria-label='Pixward'
        />
      </LogoWrapper>

      {screenType === SCREEN_TYPES.DESKTOP ? <HeaderUl>
        <HeaderLi url={ASSETS.components.header.BFLogo} width={13.8} height={7.6} marginLeft={-6.9}
          className='scaleOnHover' title='Boss Fighters' aria-label='Boss Fighters'
          onClick={() => window.open('https://bossfighters.game/', '_blank')}/>
        <HeaderLi url={ASSETS.components.header.About} width={5.2} height={4.4} marginLeft={4}
          className='scaleOnHover' title='About' aria-label='About'
          onClick={() => scrollToElement('pixward-about-block')}/>
        <HeaderLi url={ASSETS.components.header.Contact} width={6.2} height={4.4} marginLeft={4}
          className='scaleOnHover' title='Contact' aria-label='Contact'
          onClick={() => scrollToElement('pixward-contact-block')}/>
      </HeaderUl> : <BurgerWrapper isCross={!isOutAnimation} onClick={menuClickHandler}
        title={`${isOutAnimation ? 'Open' : 'Close'} menu`}
        aria-label={`${isOutAnimation ? 'Open' : 'Close'} menu`}>
        <BurgerLine/>
        <BurgerLine isVisible={isOutAnimation}/>
        <BurgerLine/>
      </BurgerWrapper>}

      {isBurgerMenuVisible && (
        <BurgerMenu isOutAnimation={isOutAnimation}>
          <HeaderUl style={{flexDirection: 'column', height: '100%', justifyContent: 'center'}}>
            <HeaderLi url={ASSETS.components.header.BFLogo} width={50} height={9.4}
              className='scaleOnHover' title='Boss Fighters' aria-label='Boss Fighters'
              onClick={() => {
                menuClickHandler()
                window.open('https://bossfighters.game/', '_blank')
              }}/>
            <HeaderLi url={ASSETS.components.header.About} width={20} height={5.5} className='scaleOnHover'
              style={{marginBottom: '1.5vh'}} title='About' aria-label='About'
              onClick={() => scrollToElement('pixward-about-block')}/>
            <HeaderLi url={ASSETS.components.header.Contact} width={24} height={5.5}
              className='scaleOnHover' title='Contact' aria-label='Contact'
              onClick={() => scrollToElement('pixward-contact-block')}/>
          </HeaderUl>
        </BurgerMenu>
      )}
    </Wrapper>
  );
};

export default Header;