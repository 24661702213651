import styled from 'styled-components';

export const Image = styled.img<{
    width?: number,
    height?: number,
    top?: number,
    left?: number,
    bottom?: number,
    right?: number,
    objectPosition?: string,
    zIndex?: number
}>`
  width: ${({width}) => width}%;
  height: ${({height}) => height}%;

  position: absolute;
  top: ${({top}) => top}%;
  left: ${({left}) => left}%;
  bottom: ${({bottom}) => bottom}%;
  right: ${({right}) => right}%;
  z-index: ${({zIndex}) => zIndex};

  object-fit: contain;
  object-position: ${({objectPosition}) => objectPosition};
`