import React from 'react';

import {GOOGLE_RECAPTCHA_SITE_KEY} from '../../config';

const LazyRecaptcha = React.lazy(() => import('react-google-recaptcha'));

const RecaptchaLoader = () => (
  <React.Suspense fallback={null}>
    <LazyRecaptcha
      ref={window.recaptchaRef}
      size="invisible"
      theme='dark'
      sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
      style={{position: 'relative', zIndex: 10}}
    />
  </React.Suspense>
);

export default RecaptchaLoader;
