import styled from 'styled-components';
import {SCREEN_TYPES} from '../../hooks/useScreenSizeHook/useScreenSizeHook';

export const Wrapper = styled.footer<{ url?: string, screenType: SCREEN_TYPES }>`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 1 : 10};

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? '37vh' : (screenType === SCREEN_TYPES.MOBILE ? '29.2vh' : '36vh')};

  background-image: url(${({url}) => url});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;

  padding: 0 ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? '4vw' : '15vw'} 0 ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? '4vw' : '7vw'};

  box-sizing: border-box;

  pointer-events: none;
`

export const FooterColumn = styled.div<{ flex?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: ${({flex = 0.6}) => flex};

  pointer-events: all;
`
