import React, {LazyExoticComponent} from 'react';

const Main = React.lazy(() => import('../pages/Main'));
const PrivacyPolicy = React.lazy(() => import('../pages/PrivacyPolicy'));

export interface IRoute {
    path: string,
    exact: boolean,
    name: string,
    element: LazyExoticComponent<() => JSX.Element>,
}

export const publicRoutes: IRoute[] = [
  {path: '*', exact: true, name: 'Main', element: Main},
  {path: '/privacy-policy', exact: true, name: 'Privacy Policy', element: PrivacyPolicy},
];