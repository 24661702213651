import React from 'react';
import {useNavigate} from 'react-router-dom';

import useScreenSizeHook, {SCREEN_TYPES} from '../../hooks/useScreenSizeHook/useScreenSizeHook';

import LazyImage from '../../components/LazyImage/LazyImage';

import ASSETS from '../../assets/images/constants';

import {FooterColumn, Wrapper} from './styles';
import {BaseImg, BaseText, HrefWrapper, LinkWrapper} from '../../pages/Main/styles';

const Footer = () => {
  const {screenType} = useScreenSizeHook()
  const navigate = useNavigate();

  return (
    <Wrapper
      url={screenType === SCREEN_TYPES.DESKTOP ? ASSETS.components.footer.desktop.FooterBg1 : ASSETS.components.footer.mobile.FooterMobileBg}
      screenType={screenType}
      style={{backgroundPosition: screenType === SCREEN_TYPES.TABLET ? 'center' : 'bottom'}}>
      {screenType !== SCREEN_TYPES.DESKTOP &&
                <LazyImage src={ASSETS.components.footer.mobile.FollowUs} width={31} height={10}
                  right={screenType === SCREEN_TYPES.MOBILE ? 7 : 6}
                  bottom={42} className='scaleOnHover' style={{pointerEvents: 'all'}}
                  title='Follow us on LinkedIn'
                  onClick={() => window.open('https://www.linkedin.com/company/pixward-games/', '_blank')}/>}

      <Wrapper url={screenType === SCREEN_TYPES.DESKTOP ? ASSETS.components.footer.desktop.FooterBg2 : undefined}
        screenType={screenType}
        style={{
          height: screenType === SCREEN_TYPES.DESKTOP ? '15.5vh' : '14vh',
          paddingTop: screenType === SCREEN_TYPES.DESKTOP ? '2.9vh' : (screenType === SCREEN_TYPES.MOBILE ? '5vh' : '3.5vh'),
          justifyContent: screenType === SCREEN_TYPES.MOBILE ? 'flex-start' : 'center',
        }}>
        <FooterColumn className='text-s font-w-500 white-color'
          style={{justifyContent: screenType === SCREEN_TYPES.DESKTOP ? 'center' : 'flex-start'}}
          flex={screenType === SCREEN_TYPES.DESKTOP ? 0.6 : (screenType === SCREEN_TYPES.MOBILE ? 0.8 : 1)}>
          <BaseImg src={ASSETS.components.header.PixwardLogo}
            width={screenType === SCREEN_TYPES.DESKTOP ? 44.8 : (screenType === SCREEN_TYPES.MOBILE ? 80 : 55)}
            height={100} alt='Pixward' title='Pixward' aria-label='Pixward'/>
          {screenType === SCREEN_TYPES.DESKTOP && <>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: '100%',
              marginRight: '6%',
              whiteSpace: 'nowrap'
            }}>
              <BaseImg src={ASSETS.components.footer.desktop.C2023} width={15.5} height={100}
                style={{marginRight: '6%'}} alt='In' title='In' aria-label='In'/>
              <BaseText>2024</BaseText>
            </div>
            <BaseText style={{width: '100%', whiteSpace: 'nowrap'}}>Pixward Games</BaseText>
          </>}
        </FooterColumn>

        {screenType === SCREEN_TYPES.DESKTOP ? <>
          <FooterColumn flex={1} className='text-s font-w-500 white-color'>
            <LinkWrapper to='/privacy-policy' className='scaleOnHover'
              title='Privacy Policy' aria-label='Privacy Policy'>
                            Privacy Policy
            </LinkWrapper>
          </FooterColumn>
          <FooterColumn>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              marginLeft: '10%',
              whiteSpace: 'nowrap'
            }} className='text-l font-w-700 white-color'>
              <HrefWrapper href='https://www.linkedin.com/company/pixward-games/' target="_blank"
                className='scaleOnHover' title='Follow us on LinkedIn'
                aria-label='Follow us on LinkedIn'>
                <BaseText>Follow us
                  <BaseImg src={ASSETS.mainPage.leadership.desktop.Linkedin} width={18} height={100}
                    style={{marginLeft: '6%', marginBottom: '4%', verticalAlign: 'middle'}}
                    alt='LinkedIn' title='LinkedIn' aria-label='LinkedIn'/>
                </BaseText>
              </HrefWrapper>
            </div>
          </FooterColumn>
        </> : <div
          className={`${screenType === SCREEN_TYPES.MOBILE ? 'text-mob-s' : 'text-tab-s'} font-w-500 white-color`}
          style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '3%'}}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: '100%',
              marginRight: '10%',
              whiteSpace: 'nowrap'
            }}>
              <BaseImg src={ASSETS.components.footer.desktop.C2023} width={31.5} height={100}
                style={{marginRight: '10%'}} alt='In' title='In' aria-label='In'/>
              <BaseText>2024</BaseText>
            </div>
            <BaseText style={{width: '100%', whiteSpace: 'nowrap'}}>Pixward Games</BaseText>
          </div>

          <FooterColumn flex={1}>
            <LinkWrapper to='/privacy-policy' className='scaleOnHover'
              title='Privacy Policy' aria-label='Privacy Policy'>
                            Privacy Policy
            </LinkWrapper>
          </FooterColumn>
        </div>}
      </Wrapper>
    </Wrapper>
  );
};

export default Footer;