import React, {Fragment, useRef} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import DefaultLayout from './components/layout/DefaultLayout';
import Loader from './components/Loader/Loader';

import ScrollToTop from './utils/ScrollToTop/ScrollToTop';

import './scss/style.scss';
import './index.css';

const loader = (
  <Fragment>
    <div className="loader-container">
      <Loader size={64} borderWidth={6}/>
    </div>
  </Fragment>
);


function App() {
  window.recaptchaRef = useRef<ReCAPTCHA>(null);

  return (
    <>
      <Router>
        <ScrollToTop/>
        <React.Suspense fallback={loader}>
          <Routes>
            <Route path="*" element={<DefaultLayout/>}/>
          </Routes>
        </React.Suspense>
      </Router>
    </>
  );
}

export default App;
