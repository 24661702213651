import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const Wrapper = styled.div<{ url1: string, url2?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  position: relative;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100vw;
  height: 100vh;

  background-image: url(${({url1}) => url1}), url(${({url2}) => url2});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`

export const BaseImg = styled.img<{
    width: number,
    height: number,
    marginTop?: number,
    marginRight?: number,
    marginBottom?: number,
    marginLeft?: number,
    isPercent?: boolean
}>`
  width: ${({width}) => width}${({isPercent = true}) => (isPercent ? '%' : 'vw')};
  height: ${({height}) => height}${({isPercent = true}) => (isPercent ? '%' : 'vh')};

  margin-top: ${({marginTop = 0}) => marginTop}${({isPercent = true}) => (isPercent ? '%' : 'vh')};
  margin-right: ${({marginRight = 0}) => marginRight}${({isPercent = true}) => (isPercent ? '%' : 'vh')};
  margin-bottom: ${({marginBottom = 0}) => marginBottom}${({isPercent = true}) => (isPercent ? '%' : 'vh')};
  margin-left: ${({marginLeft = 0}) => marginLeft}${({isPercent = true}) => (isPercent ? '%' : 'vh')};

  object-fit: contain;
`

export const BaseLink = styled.a<{
    width: number,
    height: number,
    marginTop?: number,
    marginRight?: number,
    marginBottom?: number,
    marginLeft?: number,
    isPercent?: boolean,
    url: string,
}>`
  width: ${({width}) => width}${({isPercent = true}) => (isPercent ? '%' : 'vw')};
  height: ${({height}) => height}${({isPercent = true}) => (isPercent ? '%' : 'vh')};

  margin-top: ${({marginTop = 0}) => marginTop}${({isPercent = true}) => (isPercent ? '%' : 'vh')};
  margin-right: ${({marginRight = 0}) => marginRight}${({isPercent = true}) => (isPercent ? '%' : 'vh')};
  margin-bottom: ${({marginBottom = 0}) => marginBottom}${({isPercent = true}) => (isPercent ? '%' : 'vh')};
  margin-left: ${({marginLeft = 0}) => marginLeft}${({isPercent = true}) => (isPercent ? '%' : 'vh')};

  background-image: url(${({url}) => url});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`

export const HrefWrapper = styled.a`
  text-decoration: none;
  color: inherit;
`

export const LinkWrapper = styled(Link)`
  text-decoration: none;
  color: inherit;
`

export const BaseText = styled.span<{ marginTop?: number }>`
  margin-top: ${({marginTop = 0}) => marginTop}%;
`

export const WrapperWithParallaxBackground = styled.div<{ background: string, moveX: number, moveY: number }>`
  position: absolute;
  top: -10%;
  left: -10%;

  width: 120%;
  height: 120%;

  background: url("${({background}) => background}") no-repeat center center;
  background-size: cover;

  transform: ${({moveX, moveY}) => `translate(${moveX}px, ${moveY}px)`};
`;


export const ParallaxItem = styled.div<{
    moveX: number;
    moveY: number,
    top: number,
    left: number,
    cursorPosition: { x: number, y: number },
    moveMultiplier: { x: number, y: number },
}>`
  position: absolute;
  top: ${({top}) => top}%;
  left: ${({left}) => left}%;

  transform: ${({moveX, moveY, cursorPosition, moveMultiplier}) =>
    `translate(${(cursorPosition.x * moveX * moveMultiplier.x) / 500}px, ${(cursorPosition.y * moveY * moveMultiplier.y) / 500}px)`};
`;

export const ParallaxImg = styled.img<{ width: number, height: number }>`
  width: ${({width}) => width}vw;
  height: ${({height}) => height}vh;

  object-fit: contain;
`;

export const TextWrapper = styled.div<{
    url: string,
    width: number,
    height: number,
    marginTop?: number,
    marginBottom?: number,
    isPercent?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${({width}) => width}${({isPercent = true}) => (isPercent ? '%' : 'vw')};
  height: ${({height}) => height}${({isPercent = true}) => (isPercent ? '%' : 'vh')};

  background-image: url(${({url}) => url});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  margin-top: ${({marginTop = 0}) => marginTop}${({isPercent = true}) => (isPercent ? '%' : 'vh')};
  margin-bottom: ${({marginBottom = 0}) => marginBottom}${({isPercent = true}) => (isPercent ? '%' : 'vh')};
`

