import {useState, useEffect} from 'react';

const useHeaderAnimation = () => {
  const [isHeaderVisible, setIsHeaderVisible] = useState<boolean>(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [scrollingTimeout, setScrollingTimeout] = useState<NodeJS.Timeout | null>(null);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (scrollingTimeout) {
      clearTimeout(scrollingTimeout);
    }

    setScrollingTimeout(
      setTimeout(() => {
        if (currentScrollPos <= 100) {
          setIsHeaderVisible(true);
        } else if (currentScrollPos > prevScrollPos) {
          setIsHeaderVisible(false);
        } else {
          setIsHeaderVisible(true);
        }

        setPrevScrollPos(currentScrollPos);
      }, 100)
    );
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos, scrollingTimeout]);

  return isHeaderVisible;
};

export default useHeaderAnimation;
