import React, {CSSProperties, RefObject} from 'react';

import {Image} from './styles';

interface ILazyImage {
    src: string;
    title: string;

    width: number;
    height: number;

    top?: number;
    left?: number;
    bottom?: number;
    right?: number;

    zIndex?: number;
    objectPosition?: string;

    ref?: RefObject<HTMLImageElement>;
    className?: string;
    style?: CSSProperties;

    onClick?: () => void;
}

const LazyImage = ({
  src,
  title,
  width,
  height,
  top,
  left,
  bottom,
  right,
  zIndex,
  objectPosition,
  ref,
  className,
  style,
  onClick,
}: ILazyImage) => {

  return (
    <Image src={src} title={title} alt={title} aria-label={title} width={width} height={height}
      top={top} left={left} bottom={bottom} right={right} objectPosition={objectPosition}
      zIndex={zIndex} ref={ref} className={className} style={style} loading="lazy" onClick={onClick}/>
  );
};

export default LazyImage;
