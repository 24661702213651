import styled, {keyframes, css} from 'styled-components';

const rotateIn = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(45deg);
  }
`;

const slideInRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideOutRight = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
`;

export const Wrapper = styled.header<{ height: number, padding: number }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  display: flex;
  align-items: center;

  width: 100%;
  height: ${({height}) => height}vh;

  padding: 0 ${({padding}) => padding}vw;

  box-sizing: border-box;

  pointer-events: none;
`

export const LogoWrapper = styled.div`
  flex: 1;
  padding: 0;
`;

export const HeaderLogo = styled.img<{ width: number, height: number }>`
  width: ${({width}) => width}%;
  height: ${({height}) => height}%;

  pointer-events: all;
`

export const HeaderUl = styled.ul`
  display: flex;
  align-items: center;
  flex: 1;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const HeaderLi = styled.li<{ width: number, height: number, url: string, marginLeft?: number }>`
  width: ${({width}) => width}vw;
  height: ${({height}) => height}vh;

  background-image: url(${({url}) => url});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  margin-left: ${({marginLeft = 0}) => marginLeft}vw;

  pointer-events: all;
`

export const BurgerWrapper = styled.div<{ isCross: boolean }>`
  position: relative;
  z-index: 5;

  width: 11.5%;
  height: 47%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  pointer-events: all;

  transition: all 0.3s ease-in-out;

  ${({isCross}) =>
    isCross &&
          css`
            & > ${BurgerLine}:first-child {
              transform: translate(0, 200%) rotate(45deg) skewX(-20deg);
            }

            & > ${BurgerLine}:last-child {
              transform: translate(0, -200%) rotate(-45deg) skewX(20deg);
            }
          `}
`;

export const BurgerLine = styled.div<{ isVisible?: boolean }>`
  width: 100%;
  height: 20%;

  opacity: ${({isVisible = true}) => (isVisible ? 1 : 0)};
  transform: skewX(-20deg) ${({isVisible = true}) => (isVisible ? 'scale(1)' : 'scale(0)')};

  background-color: #fff;

  transition: all 0.3s ease-in-out;
`;

export const BurgerMenu = styled.div<{ isOutAnimation: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;

  width: 100vw;
  height: 100vh;

  background: #141543;

  opacity: 0;
  animation: ${({isOutAnimation}) => (isOutAnimation ? slideOutRight : slideInRight)} 0.3s ease-in-out forwards;
`;