import React, {Suspense, Fragment} from 'react';
import {Route, Routes} from 'react-router-dom';

import {IRoute, publicRoutes} from '../routes/routes';

import Loader from './Loader/Loader';

const AppContent = () => {
  const loader = (
    <Fragment>
      <div className="loader-container">
        <Loader size={64} borderWidth={6}/>
      </div>
    </Fragment>
  );

  return (
    <Suspense fallback={loader}>
      <Routes>
        {publicRoutes.map((route: IRoute, idx) => route.element &&
                    <Route key={idx} path={route.path} element={<route.element/>}/>)}
      </Routes>
    </Suspense>
  );
};

export default React.memo(AppContent);
